<template>
  <el-dialog
    top="80px"
    width="600px"
    class="create-user-popup"
    title="Thêm tài khoản cho tổ chức"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="Tên tổ chức" prop="summary">
        <el-input v-model="form.summary" readonly placeholder="Nhập tên tổ chức"></el-input>
      </el-form-item>
      <el-form-item label="Danh sách tài khoản" prop="users">
        <el-select
          v-model="form.users"
          multiple
          filterable
          :disabled="callingAPI"
          placeholder="Chọn tài khoản cho tổ chức"
          class="w-100"
          remote
          :remote-method="searchUsers"
          :loading="loading"
        >
          <el-option
            v-for="(user, index) in users"
            :key="index"
            :label="user.email"
            :value="user.id"
            :disabled="!!organization.users.find((u) => u.id === user.id)"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateOrg">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateOrganization } from '@/services/organizations'
import { getUsers } from '@/services/user'

export default {
  name: 'AddUsers',
  props: {
    visible: Boolean,
    organization: Object
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      users: [],
      searchText: '',
      form: {
        summary: this.organization.summary,
        users: []
      }
    }
  },
  computed: {
    oldUserIds() {
      return this.organization.users.map((u) => u.id)
    },
    oldPlaceIds() {
      return this.organization.places.map((u) => u.id)
    },
    dataInput() {
      return {
        id: this.organization.id,
        user_ids: [...this.oldUserIds, ...this.form.users],
        place_ids: this.oldPlaceIds
      }
    },
    params() {
      return {
        page: 1,
        per_page: 15,
        user_types: 'staff',
        search_text: this.searchText,
        staff_types: 'moderator'
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getUsers(this.params).then((response) => {
        this.users = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleUpdateOrg() {
      this.callingAPI = true
      updateOrganization(this.dataInput).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Thêm mới tài khoản thành công'
        })
        this.$refs.form.resetFields()
        this.callingAPI = false
        this.$emit('reload')
        this.$emit('close')
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Không thể thêm tài khoản cho tổ chức'
        })
        this.callingAPI = false
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },

    searchUsers(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
