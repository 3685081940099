<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column width="60">
      <template slot-scope="scope">
        <el-avatar size="large" :src="scope.row.avatar_url"></el-avatar>
      </template>
    </el-table-column>
    <el-table-column label="Tên" prop="name" min-width="200"></el-table-column>
    <el-table-column label="Địa chỉ email" prop="email" min-width="200"></el-table-column>
    <el-table-column label="Số điện thoại" prop="phone" min-width="200"></el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" width="120"></el-table-column>
    <el-table-column label="Thao tác" width="100" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Xóa user" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="$emit('delete', scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ListUser',
  props: {
    data: Array,
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
</style>
