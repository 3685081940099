<template>
  <div v-loading="loading" class="organizations-info">
    <org-infomation v-if="organization" :organization="organization" />
    <org-menus v-if="organization" :organization="organization" @reload="loadData()" />
    <org-users v-if="organization" :organization="organization" @reload="loadData()" />
    <org-content v-if="organization" :organization="organization" @reload="loadData()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrgInfomation from './components/Infomation.vue'
import OrgMenus from './components/Menus.vue'
import OrgUsers from './components/Users.vue'
import OrgContent from './components/Contents.vue'
import { getOrganizationById } from '@/services/organizations'

export default {
  name: 'OrganizationsInfo',
  components: {
    OrgInfomation,
    OrgMenus,
    OrgUsers,
    OrgContent
  },
  data() {
    return {
      loading: true,
      organization: null
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData(true)
  },
  methods: {
    async loadData(loading = false) {
      this.loading = loading
      await getOrganizationById({ id: this.$route.params.id }).then((response) => {
        if (response.result.length) {
          this.organization = response.result.find((r) => r.id === this.$route.params.id)
        } else {
          this.$router.push({ name: 'PageNotFound' })
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.organizations-info {
  min-height: 200px;
  .title {
    background-color: #2b314a;
    padding: 15px;
    margin-bottom: 20px;
    h3 {
      margin: 0;
      color: white;
    }
  }
}
</style>

