<template>
  <el-dialog
    top="80px"
    width="900px"
    class="adding-places-popup"
    title="Thêm điêm đến cho tổ chức"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" class="p-3">
      <el-form-item label="Loại">
        <el-select v-model="placeType" disabled class="w-100">
          <el-option v-for="t in placeTypes" :key="t.value" :label="t.name" :value="t.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!['tour', 'post'].includes(placeType)"
        :label="`${currentTab.name} ${['attraction', 'museum'].includes(placeType) ? 'cha' : ''}`"
      >
        <el-select
          v-model="form.places"
          class="w-100 set-height"
          multiple
          filterable
          remote
          :placeholder="`Chọn ${currentTab.name}`"
          :remote-method="searchObjectByType"
          :loading="loading"
          :disabled="callingAPI"
          @change="changeParentPlaces"
        >
          <el-option
            v-for="p in places"
            :key="p.id"
            :label="p.name"
            :value="p.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!['tour', 'post'].includes(placeType) && ['attraction', 'museum'].includes(placeType)"
        :label="`${currentTab.name} con`"
      >
        <el-select
          v-model="form.childrenPlaces"
          class="w-100 set-height"
          multiple
          filterable
          :disabled="callingAPI || !form.places || form.places.length === 0"
          :placeholder="`Chọn ${currentTab.name}`"
          :loading="loading"
        >
          <el-option
            v-for="p in childrenPlaces"
            :key="p.id"
            :label="p.name"
            :value="p.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="placeType === 'post'" label="Bài viết">
        <el-select
          v-model="form.posts"
          class="w-100"
          multiple
          filterable
          remote
          placeholder="Chọn bài viết"
          :remote-method="searchObjectByType"
          :loading="loading"
        >
          <el-option v-for="p in posts" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="placeType === 'tour'" label="Tour course">
        <el-select
          v-model="form.tours"
          class="w-100"
          multiple
          filterable
          remote
          placeholder="Chọn tour"
          :remote-method="searchObjectByType"
          :loading="loading"
        >
          <el-option v-for="t in tours" :key="t.id" :label="t.name" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateOrg">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces } from '@/services/place'
import { listPosts } from '@/services/post'
import { listTours } from '@/services/tour'
import { getPlaceTypes, orgContentIds } from '@/utils/filters'
import { updateOrganization } from '@/services/organizations'

export default {
  name: 'AddPlaces',
  props: {
    visible: Boolean,
    organization: Object,
    placeType: String,
    currentTab: Object
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      places: [],
      childrenPlaces: [],
      posts: [],
      tours: [],
      searchText: '',
      form: {
        placeType: this.placeType,
        places: [],
        childrenPlaces: [],
        posts: [],
        tours: []
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    oldPlaceIds() {
      return orgContentIds('places', this.organization)
    },
    oldTourIds() {
      return orgContentIds('tours', this.organization)
    },
    oldPostIds() {
      return orgContentIds('posts', this.organization)
    },
    oldUserIds() {
      return orgContentIds('users', this.organization)
    },
    dataInput() {
      return {
        id: this.organization.id,
        place_ids: [...new Set([...this.oldPlaceIds, ...this.form.places, ...this.form.childrenPlaces])],
        tour_ids: [...new Set([...this.oldTourIds, ...this.form.tours])],
        post_ids: [...new Set([...this.oldPostIds, ...this.form.posts])],
        user_ids: this.oldUserIds
      }
    },
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    params() {
      return {
        locale: this.language,
        place_types: this.placeType,
        per_page: 10,
        search_text: this.searchText,
        only_parent: true
      }
    }
  },
  beforeMount() {
    this.loadData()
    this.getAllChildrenPlaces()
  },
  methods: {
    loadData() {
      this.loading = true
      let request = null
      if (this.placeType === 'tour') {
        request = listTours(this.params)
      } else if (this.placeType === 'post') {
        request = listPosts(this.params)
      } else {
        request = listPlaces(this.params)
      }
      request.then((res) => {
        if (this.placeType === 'tour') {
          this.tours = res.result.filter((t) => !this.oldTourIds.includes(t.id))
        } else if (this.placeType === 'post') {
          this.posts = res.result.filter((p) => !this.oldPostIds.includes(p.id))
        } else {
          this.places = res.result.filter((p) => !this.oldPlaceIds.includes(p.id))
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    getAllChildrenPlaces() {
      if (['attraction', 'museum'].includes(this.placeType)) {
        this.loading = true
        listPlaces({
          locale: this.language,
          place_types: this.placeType,
          per_page: 100000000,
          only_children: true
        }).then((response) => {
          this.childrenPlaces = response.result
          this.loading = false
        }).catch(() => {
          this.childrenPlaces = []
          this.loading = false
        })
      }
    },

    handleUpdateOrg() {
      this.callingAPI = true
      updateOrganization(this.dataInput).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Thêm mới nội dung thành công'
        })
        this.$refs.form.resetFields()
        this.callingAPI = false
        this.$emit('reload')
        this.$emit('close')
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Không thể thêm nội dung cho tổ chức'
        })
        this.callingAPI = false
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },

    changeParentPlaces(parentPlaces) {
      const childrenPlaces = this.childrenPlaces.filter((p) => parentPlaces.includes(p.parent_id))
      this.form.childrenPlaces = childrenPlaces.map((p) => p.id)
    },

    searchObjectByType(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.adding-places-popup {
  .el-dialog__body {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
