<template>
  <div class="org-users shared-index">
    <h3 class="m-0">Phân quyền menus</h3>
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs value="orgUsers" type="card">
          <el-tab-pane name="orgUsers">
            <div slot="label">
              Danh sách Menus
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          v-if="!isEdit"
          type="primary"
          icon="el-icon-edit"
          size="small"
          @click="isEdit = true"
        >Cập nhật menus</el-button>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <p v-if="!orgMenus.length">Tổ chức chưa có menus nào</p>
      <div v-if="!isEdit">
        <el-tag
          v-for="menu in allOrgMenus"
          :key="menu.index"
          class="mr-2 mb-2"
          size="large"
          type="info"
        >{{ menu.title }}</el-tag>
      </div>
      <el-select
        v-else
        v-model="orgMenus"
        multiple
        filterable
        :disabled="callingAPI"
        placeholder="Chọn menu tổ chức được quản lý"
        class="w-100 mb-3"
      >
        <el-option
          v-for="(menu, index) in menus"
          :key="index"
          :label="menu.title"
          :value="menu.type"
        ></el-option>
      </el-select>
      <el-button
        v-if="isEdit"
        type="primary"
        icon="el-icon-check"
        size="small"
        :loading="callingAPI"
        @click="handleUpdateOrg"
      >Lưu</el-button>
      <el-button
        v-if="isEdit"
        type="danger"
        icon="el-icon-close"
        size="small"
        :disabled="callingAPI"
        @click="handleClose"
      >Hủy</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateOrganization } from '@/services/organizations'
import { getAccountRouters, orgContentIds } from '@/utils/filters'
const adminMenus = ['campaign', 'comment', 'organizations', 'user']

export default {
  name: 'OrgMenus',
  props: {
    organization: Object
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      callingAPI: false,
      menus: getAccountRouters().filter((m) => !adminMenus.includes(m.type)),
      orgMenus: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    oldOrgMenus() {
      return this.organization && this.organization.menus ? this.organization.menus.split(',') : []
    },
    allOrgMenus() {
      return this.orgMenus.map((m) => this.menus.find((p) => p.type === m))
    },
    total() {
      return this.allOrgMenus.length
    },
    dataInput() {
      return {
        id: this.organization.id,
        place_ids: orgContentIds('places', this.organization),
        user_ids: orgContentIds('users', this.organization),
        tour_ids: orgContentIds('tours', this.organization),
        post_ids: orgContentIds('posts', this.organization),
        menus: this.orgMenus.join(',')
      }
    }
  },
  beforeMount() {
    this.orgMenus = this.oldOrgMenus
  },
  methods: {
    handleClose() {
      this.isEdit = false
      this.orgMenus = this.organization && this.organization.menus ? this.organization.menus.split(',') : []
    },
    handleUpdateOrg() {
      this.callingAPI = true
      updateOrganization(this.dataInput).then(() => {
        this.$notify.success({
          title: 'Thành công',
          message: 'Thêm mới menu thành công'
        })
        this.$emit('reload')
        this.callingAPI = false
        this.isEdit = false
      }).catch(() => {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Không thể thêm menu cho tổ chức'
        })
        this.callingAPI = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
